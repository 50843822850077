<template>
<section class="video-container col-md-12">
    <div class="videoWrapper">
        <iframe @mouseenter="mouseEnterHandler" @mouseleave="mouseLeaveHandler"
                :src="videoSource">
        </iframe>
    </div>
    <div class="text-wrapper"
            @mouseenter="mouseEnterTextHandler"
            @mouseleave="mouseLeaveTextHandler"
    >
        <div class="row justify-content-md-center video-title">
            <h1 class="col-md-6">{{video.title}}</h1>
        </div>
        <div class="row justify-content-md-center video-text">
            <p class="col-md-8">{{video.text}}</p>
        </div>
    </div>
</section>
</template>

<script>
import defaultRequest from "@/axiosConfig";
import { inject } from 'vue'
export default {
    name: "VideoDetails",
    setup() {
        const deactivateMouse = inject('deactivateMouse')
        const insideIFrame = inject('insideIFrame')
        const hideImage = inject('hideImage', hideImage)
        return {
            deactivateMouse,
            insideIFrame,
            hideImage
        }
    },
    created() {
        this.hideImage = false
        defaultRequest
            .get('/videoinformation/slug/' + this.$route.params.slug, {
                responseType: "json",
            })
            .then((response) => {
                this.video = response.data.data;
                if (response.data.data.source.includes('https://www.youtube.com/')) {
                    this.videoSource = response.data.data.source.replace('watch?v=', 'embed/')
                } else {
                    this.videoSource = response.data.data.source
                }
            });
    },
    beforeUpdate() {
        this.hideImage = false
    },
    data: () => {
        return {
            video: {},
            videoSource: ''
        }
    },
    methods: {
        mouseEnterHandler() {
            this.insideIFrame = true
        },
        mouseLeaveHandler() {
            this.insideIFrame = false
        },
        mouseEnterTextHandler() {
            console.log(this.insideText)
            this.insideText = true
        },
        mouseLeaveTextHandler() {
            console.log(this.insideText)
            this.insideText = false
        }
    }
}
</script>

<style scoped>
    h1 {
        text-transform: capitalize;
        letter-spacing: 2px;
        margin-top: -15%;
    }
    .video-container {
        text-align: center;
        margin-top: 100px;
    }
    p {
        margin-top: 2%;
        text-align: justify;
        text-justify: inter-word;
    }
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        text-align: center;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 15%;
        width: 70%;
        height: 70%;
    }
    .video-text {
        width: 100%;
        margin-top: -13%;
    }
    .video-title {
        width: 100%;
    }
    @media (max-width: 768px) {
        .videoWrapper {
            width: 100%;
        }
        .videoWrapper iframe {
            left: 5%;
            width: 90%;
            height: 90%;
        }
        .video-container {
            margin-top: 35%;
        }
        .video-text {
            padding: 20px;
            margin-top: 15%;
        }

    }
</style>