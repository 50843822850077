<template>
  <div class="p-10 subtitle" @mouseenter="mouseEnterHandler" @mouseleave="mouseLeaveHandler"
  >
    <section class="text-right" :class="dynamicMargin">
      <span class="col-md-3 mi-links">
        <router-link to="/info" style="text-decoration: none; cursor:none;">
          <p class="marked info">Info</p>
        </router-link>
        <router-link to="/mission" style="text-decoration: none; cursor:none;">
          <p class="marked">Mission</p>
        </router-link>
      </span>
    </section>
    <section class="sub-mobile">
      <p :class="classObject">_Founded on an undying belief in the moving image.</p>
      <p :class="classObject">To entertain us, to inspire us, to make us better.&#171;</p>
      <p class="marked">WE ARE A CREATIVE FILM COMPANY BASED IN SUNNY LISBON.</p>
    </section>
  </div>
</template>

<script>
  import {inject} from "vue";

export default {
  name: "TheSubtitle",
  setup() {
    const insideSubtitle = inject('insideSubtitle')
    const navbarScrolled = inject('navbarScrolled')
    return {
      insideSubtitle,
      navbarScrolled,
    }
  },
  methods: {
    mouseEnterHandler() {
      this.insideSubtitle = true
    },
    mouseLeaveHandler() {
      this.insideSubtitle = false
    }
  },
  computed: {
    classObject() {
      return {
        'hide': this.navbarScrolled,
        'show': !this.navbarScrolled
      }
    },
    dynamicMargin() {
      return {
        'separate': this.navbarScrolled,
        'together': !this.navbarScrolled
      }
    }
  }
}
</script>

<style scoped>
  .text-right {
    text-align: center;
    margin-left: 60%;
    margin-bottom: 8px;
  }
  .info {
    margin-right: 10%;
  }
  .subtitle {
    margin-left: -5%;
    margin-top: 10px;
  }
  .marked {
    box-shadow: 15px 0 0 0 #000, -5px 0 0 0 #000;
    background: #000;
    color: white;
    display: inline;
    padding: 3px 0 !important;
    position: relative;
  }
  .p-10 {
    padding: 10px;
  }
  p {
    line-height: 0.9em;
    font-size: 21px;
  }
  .hide {
    display: none;
    height: 30px;
  }
  .show {
    display: block;
  }
  .separate {
    margin-bottom: 40px;
  }
  .together {
    margin-bottom: 30px !important;
  }
  @media only screen and (max-width: 768px) {
    p {
      line-height: 0.9em;
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 1440px) {
    p {
      font-size: 17px;
      line-height: 1em;
    }
    .together {
      margin-bottom: 18px !important;
    }
    .text-right {
      /*margin-left: 68%;*/
    }
    .mi-links {
      margin-left: 40px;
    }
    .separate {
      margin-bottom: 25px;
    }
    .sub-mobile {
      width: 100%;
      margin-left: 10%;
    }
  }

</style>