<template>
  <router-link :to="'/video/' + slug" class="image-wrapper" style="cursor: none">
    <section @mouseenter="mouseEnterHandler()+mouseEnterHandlerVideo()" @mouseleave="mouseLeaveHandler()+mouseLeaveHandlerVideo()">
      <img :src="filterUrl" class="image-v" alt="normal" ref="minhaimagem" />
      <video loop muted class="image-hover" ref="meuvideo">
        <source :src="thumbnailUrl"  type="video/mp4"/>
      </video>
      <video-title
              :title="title"
              :right="right"
              :mouseEnter="mouseEnter"
              :mouseLeave="mouseLeave"
      ></video-title>
    </section>
  </router-link>
</template>

<script>
import VideoTitle from "@/pages/video/VideoTitle";
import {inject} from "vue";
export default {
  name: "VideoThumb",
  components: {VideoTitle},
  props: ['filter', 'thumbnail', 'title', 'slug', 'right'],
  data: () => {
    return {
      filterUrl: '',
      thumbnailUrl: '',
      mouseEnter: 0,
      mouseLeave: 0
    }
  },
  setup() {
    const insideVideo = inject('insideVideo')
    return {
      insideVideo,
    }
  },
  created() {
    this.filterUrl = `${process.env.VUE_APP_STORAGE_ROOT}/storage/filters/${encodeURIComponent(this.filter)}`
    this.thumbnailUrl = `${process.env.VUE_APP_STORAGE_ROOT}/storage/thumbnails/${encodeURIComponent(this.thumbnail)}`
  },
  methods: {
    mouseEnterHandler() {
      this.mouseEnter = 1
      this.mouseLeave = 0
      this.$refs.meuvideo.play()
      this.$refs.minhaimagem.style.opacity = 0;
    },
    mouseLeaveHandler() {
      this.mouseEnter = 0
      this.mouseLeave = 1
      this.$refs.meuvideo.currentTime = 0;
      this.$refs.minhaimagem.style.opacity = 1;
    },
    mouseEnterHandlerVideo() {
      this.insideVideo = true
    },
    mouseLeaveHandlerVideo() {
      this.insideVideo = false
    },
  }
}
</script>

<style scoped>
  img {
    max-width: 110%;
  }
  .image-wrapper {
    position: relative;
    flex: 1;
    max-width: 43%;
    margin: 50px;
    text-align: center;
    align-content: center;
  }
  .image-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 1s ease-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-hover:hover {
    opacity: 1;
  }
  .image-v:hover {
    width: 10%;
  }
  video {
    width: 600px;
  }
  .image-v{
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    .image-v:hover {
      opacity: 1;
    }
    image-hover:hover {
      opacity: 0;
    }
  }
</style>